import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import state from "./state";

let global = {
  state,
  actions,
  mutations,
  getters,
};

export default global;
